



import VIPSection from '../sections/vip/default';




import "./style.css";


const VipPage = ()=>{
  
return (
  <div id="vipPage" class="page ">
  
  

  
  <VIPSection desktopToggle={ true } mobileToggle={
    true } bgSection={ false } mobileTitleToggle={ true } mobileBannerToggle={ true } desktopBannerToggle={
    true } mode={`scroll`} vipContentToggle={ false } aboutUsToggle={ true }
    faqToggle={ false } tncToggle={ false } benefitToggle={
    true } reviewToggle={ true } />
  
  
  

  
</div>
)
}

export default VipPage;