


import SECTION_SLOTGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';






import SlotSection from '../sections/slots/template_01';




import "./style.css";


const SlotPage = ()=>{
  
return (
  <div id="slotPage" class="page ">
  
  

  
  < SECTION_SLOTGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `slot game banner` } id={ `slot_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  <SlotSection imageSize={ `1x2` } rowMobile={
    3 } rowDesktop={ 5 } gameProviderNameToggle={
    true } contentPosition={`insideImage` } appFolderImage={``} gameProviderFolderImage={``}/>
  
  
  

  
</div>
)
}

export default SlotPage;