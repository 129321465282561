


import SECTION_MSLOTGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_MSLOTGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';



import "./style.css";


const MobileslotPageDesktop = ()=>{
  
return (
  <div id="mobileslotPageDesktop" class="page ">
  
  

  
  < SECTION_MSLOTGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `mslot_game_banner` } id={ `mslot_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_MSLOTGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_mobile_slot_game` } id={ `mslotGameContainer` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={`hover`} rowDesktop={ 4 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`APP`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`start`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  

  
</div>
)
}

export default MobileslotPageDesktop;